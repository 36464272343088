<template>
  <div class="section section__primary">
    <div>
      <h1>Grahm Manley</h1>
      <h2>Software Engineer & Serial Hobbyist</h2>
    </div>
  </div>
  <div class="section section__secondary section__secondary--two-columns hide_img">
    <div>
      <img src="avatar.jpg" style="margin-top: 30px"/>
    </div>
    <div>
      <h1>About Me</h1>
      <p>Hi! I'm a software developer based out of Denver, CO. I'd describe myself as a creator.
      I love the process of creating, for its own sake, whether it be in the form of a clean peice of code,
      a home cooked meal, or a simple song.
      I get a lot of enjoyment from learning about a medium and then creating something with that medium.</p>
      <a href="https://drive.google.com/file/d/1nd0YiIiZXPYh2kWY1gb_F6ZVtcx-pm4F/view?usp=sharing" target="_blank">Resume</a>
    </div>
  </div>
  <div class="section section__primary">
    <div>
      <div class="row hide_extra_columns">
        <div class="column">
          <img src="https://portfolio-images-grahm.s3.amazonaws.com/R1-05799-0024.JPG">
          <img src="https://portfolio-images-grahm.s3.amazonaws.com/R1-02144-0024.JPG">

        </div>
        <div class="column">
          <img src="https://portfolio-images-grahm.s3.amazonaws.com/lift.JPG">
          <img src="https://portfolio-images-grahm.s3.amazonaws.com/R1-05799-0010.JPG">
        </div>
        <div class="column">
          <img src="https://portfolio-images-grahm.s3.amazonaws.com/R1-02144-0002.JPG">
          <img src="https://portfolio-images-grahm.s3.amazonaws.com/R1-02143-023A.JPG">
        </div>
        <div class="column">
          <img src="https://portfolio-images-grahm.s3.amazonaws.com/R1-02143-004A.JPG">
          <img src="https://portfolio-images-grahm.s3.amazonaws.com/R1-07453-0015.JPG">
        </div>
      </div>
    </div>
  </div>
  <div class="section section__secondary">
    <div>
    <h1>Get in touch</h1>
    <p>Shoot me an email 👉 gr@hmmanley.com</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
$primary: #405e3e;
$secondary: #d1c3e8;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  overflow: overlay;
}

body::-webkit-scrollbar-track
{
  background-color: transparent;
}

body::-webkit-scrollbar
{
  width: 9px;
  display: none;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb
{
  background-color: $primary;
  border: 2px solid $secondary;
    background-blend-mode: difference;
}

h1 {
  font-size: 150px;
  font-family: 'Righteous', cursive;
  line-height: 120px;
  margin-bottom: 40px;
  margin-top: 20px
}

h2 {
  font-size: 70px;
  font-family: 'Jost', sans-serif;
}

p, a {
  font-size: 29px;
  font-family: 'Jost', sans-serif;
}

a {
  font-weight: bold;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

:root {
  scrollbar-width: none;
  scrollbar-gutter: both-edges;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  height: 100%;
  line-height: 35px;

  img {
    border: 5px solid $primary;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    padding: 8px 8px;
    flex-direction: row !important;

    img {
      border: none;
    }
  }

  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25%;
    max-width: 25% ;
    padding: 0 4px;
    justify-content: space-between;
  }

  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }

  &__primary {
    background: $primary;
    justify-content: flex-start;
    border: 20px solid $secondary;
    border-bottom-color: $primary;

    div {
      border: black;
    }

    h1, h2, span, p, a {
      color: $secondary;
    }

    &--two-columns {
      justify-content: space-between;

      div:nth-of-type(1) {
        width: 70%;
      }

      div:nth-of-type(2) {
        width: 30%;
      }
    }
  }

  &__secondary {
    background: $secondary;
    justify-content: flex-start;
    border: 20px solid $primary;
    border-bottom-color: $secondary;

    h1, h2, span, p, a {
      color: $primary;
    }

    &--two-columns {
      justify-content: space-between;

      div:nth-of-type(1) {
        width: 40%;
      }

      div:nth-of-type(2) {
	padding-left: 20px;
        width: 60%;

        p, h1, a {
          text-align: right;
	}
      }
    }
  }
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 70px;
    line-height: 70px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  h2 {
    font-size: 35px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  a, p {
    font-size: 19px;
  }

  .section {
    line-height: 35px;
    padding: 15px;
    &__secondary {
      &--two-columns {
        div:nth-of-type(1) {
          width: 10%;
        }

        div:nth-of-type(2) {
          width: 90%;
	}
      }
    }
  }

  .hide_img {
    img {
      display: none;
    }
  }

  .hide_extra_columns {
    .column {
      flex: 50%;
      max-width: 50% ;
    }

    div:nth-child(3), div:nth-child(4) {
      display: none;
    }
  }
}
</style>
